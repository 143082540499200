.banner div {
  color: white;
}

.bags-container {
  margin: 40px;
}

.bags-text {
  margin: 30px;
}

.bags-image {
  width: 800px;
  /* height: 271.65px; */
}

.apicanta-white-logo-image {
  width: 50px;
  height: 50px;
}
