.faq {
  margin: 100px 200px;
  font-weight: bolder;
}

.faq-mobile {
  margin: 40px 40px;
  font-weight: bolder;
}

.row-content-text {
  margin: 20px;
}
