.footer {
  margin: 100px 0 0 0;
  background: rgb(75, 57, 238);
  font-size: 24px;
  font-weight: bolder;
  color: inherit;
  text-decoration: none; /* no underline */
}

.footer .links {
  padding: 30px;
}

.footer a {
  font-size: 24px;
  font-weight: bolder;
  color: inherit;
  text-decoration: none; /* no underline */
}

.footer span {
  margin: 0px 10px;
  font-size: 24px;
  font-weight: bolder;
  color: inherit;
  text-decoration: none; /* no underline */
}

.footer img {
  margin: 10px 0;
}

.footer-mobile {
  margin: 100px 0 0 0;
  background: rgb(75, 57, 238);
  font-size: 24px;
  font-weight: bolder;
  color: inherit;
  text-decoration: none; /* no underline */
}

.footer-mobile .links {
  padding: 10px;
}

.footer-mobile a {
  font-size: 14px;
  font-weight: bolder;
  color: inherit;
  text-decoration: none; /* no underline */
}

.footer-mobile span {
  margin: 0px 10px;
  font-size: 14px;
  font-weight: bolder;
  color: inherit;
  text-decoration: none; /* no underline */
}

.footer-mobile img {
  margin: 10px 0;
  width: 35px;
  height: 35px;
}
