.header {
  color: white;
  font-size: 40px;
  font-weight: bolder;
  justify-content: center;
  align-items: center;
  margin: 30px 20px;
  display: flex;
}

.header-logo {
  width: 80px;
  padding-top: 5px;
}

.header-logo-mobile {
  width: 15%;
  padding-top: 5px;
}
