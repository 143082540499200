.my-button {
  border-radius: 900px;
  background: rgba(243, 144, 25, 0.996);
  width: 190px;
  height: 32px;
  color: white;
  font-weight: bold;
}

.my-button:hover {
  background: white;
  color: rgba(243, 144, 25, 0.996);
}
