.girl-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 55vh;
  background-image: url("../../../../images-local/header-background.png");
  /* Additional background properties */
  background-repeat: no-repeat;
  background-size: cover;
  /* ...other properties as needed */
}

.girl-image {
  width: 415px;
  height: 415px;
}

.girl-right-side div {
  -webkit-font-smoothing: antialiased;
  font-size: 23px;
  font-weight: bold;
  margin: 40px;
  max-width: 400px;
}
