/* loginPage.css */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  color: black;
  margin: 25px 0 0 0;
}

.login-form {
  width: 300px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

button {
  width: 100%;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
