.laptop-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
}

.laptop-container .laptop-image {
  width: 344px;
  height: 344px;
}

.laptop-container .we-came-here {
  color: rgb(75, 57, 238);
  font-size: 48px;
  font-weight: bold;
}

.laptop-container .passion {
  color: rgba(243, 144, 25, 0.996);
  font-size: 48px;
  font-weight: bold;
}

.laptop-container .paragraph {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  max-width: 500px;
}

.laptop-container div p {
  max-width: 400px;
}
