.weprovide-image {
  width: 437px;
  height: 242px;
}

.weprovide-image {
  width: 437px;
  height: 242px;
}

.worldwide-image-mobile {
  width: 100%;
}

.weprovide-image-mobile {
  width: 100%;
}

.worldwide-image {
  width: 500px;
  height: 277px;
}

.worldwide-image-container {
  margin: 70px;
}
