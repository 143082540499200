.nav-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-bar a {
  color: white;
  /* border: 1px solid white; */
  padding: 20px;
  font-size: 24px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.nav-bar-mobile a {
  color: white;
  /* border: 1px solid white; */
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
