/* whoWeAre.css */

.who-we-are-container-flex-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px;
}

.who-we-are-container-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  height: 60vh;
}

.who-we-are-container {
  max-width: 800px;
  margin: auto;
}

.who-we-are-header {
  text-align: center;
  margin-bottom: 20px;
}

.who-we-are-content {
  line-height: 1.6;
  text-align: justify;
  margin: 0px 20px 10px 20px;
}

.who-we-are-content h2 {
  margin-bottom: 10px;
}

.who-we-are-content .image-container {
  text-align: center;
  margin-top: 30px;
}

.who-we-are-content .image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
